import GlobalLayout from "components/page/GlobalLayout";
import LicenseComparisonPage from "components/pricing/LicenseComparisonPage";
import React from "react";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

export default function CameraLicenseComparison() {
  const licenseComparisonData = [
    {
      title: "AI & computer vision",
      items: [
        {
          text: "Heatmaps showing areas of most activity",
          p: true,
          e: true,
        },
        {
          text: "Crowd counting",
          p: false,
          e: true,
        },
        {
          text: "Unique visitor counting",
          p: false,
          e: true,
        },
        {
          text: "License plate recognition",
          p: false,
          e: true,
        },
        {
          text:
            "Facial recognition (trust & red flag specific faces and receive alerts)",
          p: false,
          e: true,
        },
        {
          text: "Unusual behavior detection",
          p: false,
          e: true,
        },
      ],
    },
    {
      title: "Video Search",
      items: [
        {
          text: "Activity/event indexing for fast searching",
          p: true,
          e: true,
        },
        {
          text: "Region search (search a particular area of video)",
          p: true,
          e: true,
        },
        {
          text: "Investigations (search across multiple cameras at once)",
          p: true,
          e: true,
        },
        {
          text:
            "Global search (search for specific people, vehicles, license plates across locations)",
          p: false,
          e: true,
        },
        {
          text: "Color search (search clothing and vehicles by color)",
          p: false,
          e: true,
        },
      ],
    },
    {
      title: "Storage",
      items: [
        {
          text: "Locally on camera for 20 - 180 days",
          p: true,
          e: true,
        },
        {
          text: "30 day cloud archiving",
          p: false,
          e: true,
        },
        {
          text: "Cloud archiving (60, 90 180+ days)",
          p: "$",
          e: "$",
        },
      ],
    },
    {
      title: "Management",
      items: [
        {
          text: "Unlimited locations",
          p: true,
          e: true,
        },
        {
          text: "Unlimited users",
          p: true,
          e: true,
        },
        {
          text: "Role management",
          p: true,
          e: true,
        },
        {
          text: "Reporting",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Video",
      items: [
        {
          text: "Real-time streaming (100-300 ms latency)",
          p: true,
          e: true,
        },
        {
          text: "View live streams and past footage",
          p: true,
          e: true,
        },
        {
          text: "Video walls for watching multiple cameras",
          p: true,
          e: true,
        },
        {
          text: "Save and share unlimited video clips",
          p: true,
          e: true,
        },
        {
          text: "2 years of cloud archiving for saved clips",
          p: true,
          e: true,
        },
        {
          text: "Shareable live streams with video history",
          p: true,
          e: true,
        },
        {
          text: "Low bandwidth consumption via WAN (300-1500 Kbps)",
          p: true,
          e: true,
        },
        {
          text: "Time-lapse videos",
          p: false,
          e: true,
        },
      ],
    },
    {
      title: "Security & Authentication",
      items: [
        {
          text: "Video is AES-256 encrypted and streamed with SSL TLS v1.2",
          p: true,
          e: true,
        },
        {
          text: "Activity audit logs",
          p: true,
          e: true,
        },
        {
          text: "Anomalous login detection",
          p: true,
          e: true,
        },
        {
          text: "Automatic firmware updates and security patches",
          p: true,
          e: true,
        },
        {
          text: "Single sign-on (SSO) via SAML",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Notifications",
      items: [
        {
          text: "Create custom policies and event notifications",
          p: true,
          e: true,
        },
        {
          text:
            "Create activity regions to only notify for motion in certain areas",
          p: true,
          e: true,
        },
        {
          text: "Custom smart alerts for motion, people, vehicles, and more",
          p: true,
          e: true,
        },
        {
          text: "Alert delivery via email, mobile apps, SMS, Slack, and more",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Integrations",
      items: [
        {
          text:
            "Microsoft Teams, Google Workspace, Okta, Slack, Kisi, and many more",
          p: true,
          e: true,
        },
        {
          text: "API access for reporting and video data",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Support",
      items: [
        {
          text: "Email support",
          p: 24,
          e: 24,
        },
        {
          text: "Phone support",
          p: 24,
          e: 24,
        },
      ],
    },
  ];
  const renderContent = data => {
    return (
      <GlobalLayout>
        <Helmet>
          <title>Rhombus Camera License Comparison - Feature Comparison</title>
          <meta
            name="description"
            content="Rhombus physical security license and feature comparison. Learn about the unique differences between Rhombus licenses."
          />
        </Helmet>
        <LicenseComparisonPage
          title1={"Camera License"}
          title2={"Comparison"}
          data={licenseComparisonData}
          headerImage={data.licenseBreakdown}
          activeTab={"camera"}
        />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      licenseBreakdown: file(
        relativePath: {
          eq: "components/pricing/img/console-license-breakdown-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
